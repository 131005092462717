import React, { useEffect, useState } from "react"
import { AiSolution1, AiSolution2, AiSolution3, AiSolution4, AiSolution5 } from "../../assets/images/solution"
import { useParams } from "react-router"
import { ourSolutionsData } from "../Homepage/homepageData"

const AiSolutionDetail = () => {
    const [Solution, setSolution] = useState(null)
    let { id } = useParams()

    useEffect(() => {
        let findSolution = ourSolutionsData.find((data) => data.title === id.replace("_", " "))
        console.log("id", id)
        console.log("findSolution", findSolution)
        console.log("Solution?.benefit.child.length", Solution?.benefit.child.length)
        if (findSolution) setSolution(findSolution)
    }, [id])

    return (
        <div className="pt-20">
            <section className="h-full px-6 sm:px-10 xl:px-0 max-w-[1200px] m-auto grid grid-cols-1 w-full py-14 sm:py-20">
                <div className="flex-col pt-4 md:pt-16 xsm:gap-3 md:gap-5 gap-y-12">
                    {Solution && (
                        <div>
                            <div className="xsm:p-3 md:p-6 min-h-[130px] flex justify-center bg-[#F7F7F7] rounded-lg cursor-pointer max-h-[400px] w-full relative mb-20 md:mb-36 w-full overflow-hidden">
                                {Solution?.banner && (
                                    <img src={Solution?.banner} alt={Solution?.title} className="h-full w-[240%] !max-w-none sm:w-fit" />
                                )}
                            </div>

                            <div
                                className="flex flex-col gap-4 md:gap-0 text-left my-20 md:my-36 text-lg md:text-2xl"
                                dangerouslySetInnerHTML={{ __html: Solution?.description }}
                            />

                            <div className={`grid grid-cols-1 md:grid-cols-3 gap-8 my-20 md:my-36`}>
                                {Solution?.child.map((child, i) => (
                                    <div key={i} className="p-6 bg-[#F7F7F7] rounded-lg flex flex-col items-center">
                                        <img src={child.img} alt={"ai_solution" + "_" + i} className="w-full max-w-[350px]"/>
                                        <p className="text-lg md:text-2xl pt-8">{child.content}</p>
                                    </div>
                                ))}
                            </div>

                            <div className="flex flex-col justify-center items-center my-20 md:my-36">
                                <p className="font-bold text-center text-2xl md:text-3xl mb-8">{Solution?.benefit?.title}</p>
                                <div
                                    className={`grid gap-8 grid-cols-1 ${
                                        Solution?.benefit?.child?.length === 3 ? "md:grid-cols-3" : "md:grid-cols-2"
                                    }`}
                                >
                                    {Solution?.benefit?.child?.map((child, i) => (
                                        <div key={i} className="p-6 bg-[#F7F7F7] rounded-lg">
                                            <p className="font-bold pb-2 text-lg">{child.title}</p>
                                            <p className="text-[#898989]">{child.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>

                                <p className="flex justify-center w-full font-semibold text-2xl sm:text-4xl text-center">{Solution?.outro}</p>
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}

export default AiSolutionDetail
