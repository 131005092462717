import { CaseIcon } from "../../assets/icons/case"
import { ChartIcon } from "../../assets/icons/chart"
import { DollarIcon } from "../../assets/icons/dollar"
import { FinanceIcon } from "../../assets/icons/finance"
import { MedalIcon } from "../../assets/icons/Medal"
import { PuzzleIcon } from "../../assets/icons/Puzzle"
import { ReloadIcon } from "../../assets/icons/Reload"
import { ResizeIcon } from "../../assets/icons/Resize"

import { ChatIcon } from "../../assets/icons/Chat"
import { PeoplesIcon } from "../../assets/icons/Peoples"
import { RunIcon } from "../../assets/icons/Run"
import { InfinityIcon } from "../../assets/icons/Infinity"
import { PlantIcon } from "../../assets/icons/Plant"

import CSIPortfolio from "../../assets/images/Portfolio/portfolio-csi.png"

import {
    Arista_Grey,
    Power_Character_Grey,
    astra,
    IOT,
    MobileApp,
    toyota,
    WebApp,
    Automotive_Auction,
    E_commerce,
    Health_Care,
    Hello_Fit,
    MrSafety_Grey
} from "../../assets/images/Home"
import {
    AiSolutionAuction,
    AiSolutionAuctionChild1,
    AiSolutionAuctionChild2,
    AiSolutionAuctionChild3,
    AiSolutionEcommerce,
    AiSolutionEcommerceChild1,
    AiSolutionEcommerceChild2,
    AiSolutionEcommerceChild3,
    AiSolutionHealtcare,
    AiSolutionHealtcareChild1,
    AiSolutionHealtcareChild2,
    AiSolutionHealtcareChild3
} from "../../assets/images/solution"

const clients = [
    { name: "Hello_Fit", image: Hello_Fit },
    { name: "Arista_Grey", image: Arista_Grey },
    { name: "astra", image: astra },
    { name: "toyota", image: toyota },
    { name: "MrSafety_Grey", image: MrSafety_Grey },
    { name: "Power_Character_Grey", image: Power_Character_Grey }
]

const FAQList = [
    {
        question: "Apa yang dimaksud Startup Approach?",
        answer: "Startup approach merupakan metodologi pengembangan produk yang bertujuan untuk mempersingkatkan siklus pengembangan produk dan dengan cepat menemukan apakah produk yang dikembangkan layak dan sesuai untuk digunakan perusahaan. Kami mengembangkan software dan strategi digital untuk perusahaan anda melalui update MVP secara berkala dan pemvalidasian produk kepada anda sesuai kebutuhan."
    },
    {
        question: "Apa benar tidak ada hidden fee sama sekali?",
        answer: "Ya Benar. Tidak ada Hidden Fee sama sekali. Fee hanya akan dikenakan apabila ada perubahan signifikan sesuai dengan kebutuhan perusahaan dan akan didiskusikan dalam proses pengembangan."
    },
    {
        question: "Apa betul scope dan project bisa dirubah di tengah jalan?",
        answer: "Ya benar. Tidak seperti Software House lain yang scope dan detail project sudah di-lock di awal perjanjian, pengembangan produk kami lebih fleksibel karena dapat dirubah sesuai dengan kebutuhan perusahaan di tengah pengembangan software."
    },
    {
        question: "Mengapa costnya bisa lebih murah?",
        answer: "Kami mengadopsi metode kerja Start-Up sehingga dapat dengan signifikan memotong biaya dan menyediakan anda jasa dengan harga yang jauh lebih murah daripada kompetitor kami."
    },
    {
        question: "Bagaimana transparansi dan mekanisme kerja developer yang dipinjamkan?",
        answer: "Kami akan secara berkala melaporkan progres kerja dalam pengembangan website atau aplikasi kepada anda sesuai dengan timeline kerja yang sudah ditentukan."
    },
    {
        question: "Apakah ada garansi terhadap software yang sudah selesai dibuat?",
        answer: "Ya"
    }
]

const projectCategory = [
    {
        name: "Web App",
        description: `Website Based Application with Various Features & Functions.`,
        active: true,
        image: WebApp
    },
    {
        name: "Mobile App",
        description: `Mobile Application Development Based on Android & IOS.`,
        active: false,
        image: MobileApp
    },
    // {
    //   name: "UI/UX Design",
    //   description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    //               Vulputate quam vel arcu fringilla arcu. Ipsum sed pellentesque
    //               ultricies magna interdum eleifend penatibus neque. Sagittis
    //               amet, lectus vel dictum in ultricies. Id dictumst risus in id
    //               placerat consectetur lectus tristique velit.`,
    //   active: false,
    // },
    // {
    //   name: "Bussiness Intelegence",
    //   description: `Support business decisions through real-time data visualization`,
    //   active: false,
    //   image: "projectImage.png",
    // },
    {
        name: "Internet of Things",
        description: `Build an integrated system between software & hardware.`,
        active: false,
        image: IOT
    }
    // {
    //   name: "Growth Strategy",
    //   description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    //               Vulputate quam vel arcu fringilla arcu. Ipsum sed pellentesque
    //               ultricies magna interdum eleifend penatibus neque. Sagittis
    //               amet, lectus vel dictum in ultricies. Id dictumst risus in id
    //               placerat consectetur lectus tristique velit.`,
    //   active: false,
    // },
    // {
    //   name: "Digital Marketing",
    //   description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    //               Vulputate quam vel arcu fringilla arcu. Ipsum sed pellentesque
    //               ultricies magna interdum eleifend penatibus neque. Sagittis
    //               amet, lectus vel dictum in ultricies. Id dictumst risus in id
    //               placerat consectetur lectus tristique velit.`,
    //   active: false,
    // },
    // {
    //   name: "Enterprise Resource Planning",
    //   description: `Support your main business needs with a flexible and affordable ERP system`,
    //   active: false,
    //   image: "projectImage.png",
    // },
    // {
    //   name: "Bussiness Process Engineering",
    //   description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    //               Vulputate quam vel arcu fringilla arcu. Ipsum sed pellentesque
    //               ultricies magna interdum eleifend penatibus neque. Sagittis
    //               amet, lectus vel dictum in ultricies. Id dictumst risus in id
    //               placerat consectetur lectus tristique velit.`,
    //   active: false,
    // },
]

const ourValues = [
    {
        title: "Deep Industry Insight",
        desc: "We recognize that every business is unique. Our AI solutions are meticulously crafted to align with your specific needs, ensuring maximum impact and value",
        icon: ChatIcon
    },
    {
        title: "Custom AI Solutions",
        desc: '"Using the insights gained from our discovery phase, we develop AI models that are custom-built for your business. Whether it’s predictive analytics, automation, or advanced data analysis, our solutions are designed to meet your exact needs.',
        icon: PeoplesIcon
    },
    {
        title: "Agile Development",
        desc: "We leverage agile methodologies to rapidly develop, test, and refine AI solutions, ensuring quick delivery and the flexibility to adapt to evolving requirements.",
        icon: RunIcon
    },
    {
        title: "Continuous Optimization",
        desc: "Our commitment doesn't end at deployment. We continuously monitor and enhance our AI solutions, driving ongoing improvements to maximize return on investment.",
        icon: InfinityIcon
    },
    {
        title: "Collaborative Partnership",
        desc: "We believe in close collaboration, working side by side with our clients to ensure seamless integration, smooth adoption, and lasting success.",
        icon: PeoplesIcon
    },
    {
        title: "Sustainable Growth",
        desc: "We committed to long-term success. We provide ongoing support and optimization services to ensure that your AI solutions continue to deliver value as your business grows and evolves.",
        icon: PlantIcon
    }
]

const dataTestimoni = [
    {
        name: "Hashi",
        from: "Caready",
        testimoni:
            "Lorem ipsum dolor sit amet consectetur. Aliquet fringilla quis eget suspendisse enim pellentesque quam sed. Quis sagittis felis malesuada lectus tincidunt dolor faucibus ipsum integer. In molestie eu felis in at eleifend risus urna morbi. Orci quis morbi pulvinar ac et faucibus adipiscing vel nisl.",
        picture: null
    },
    {
        name: "Heri",
        from: "Birdmobil",
        testimoni:
            "Lorem ipsum dolor sit amet consectetur. Aliquet fringilla quis eget suspendisse enim pellentesque quam sed. Quis sagittis felis malesuada lectus tincidunt dolor faucibus ipsum integer. In molestie eu felis in at eleifend risus urna morbi. Orci quis morbi pulvinar ac et faucibus adipiscing vel nisl.",
        picture: null
    },
    {
        name: "David Tuerah",
        from: "Astra International tso",
        testimoni:
            "Lorem ipsum dolor sit amet consectetur. Aliquet fringilla quis eget suspendisse enim pellentesque quam sed. Quis sagittis felis malesuada lectus tincidunt dolor faucibus ipsum integer. In molestie eu felis in at eleifend risus urna morbi. Orci quis morbi pulvinar ac et faucibus adipiscing vel nisl.",
        picture: null
    },
    {
        name: "David Hartono",
        from: "Asaba Group",
        testimoni:
            "Lorem ipsum dolor sit amet consectetur. Aliquet fringilla quis eget suspendisse enim pellentesque quam sed. Quis sagittis felis malesuada lectus tincidunt dolor faucibus ipsum integer. In molestie eu felis in at eleifend risus urna morbi. Orci quis morbi pulvinar ac et faucibus adipiscing vel nisl.",
        picture: null
    }
]

const dataPortfolio = [
    {
        name: "CSI Chat",
        overview:
            "Toyota TRUST adalah layanan resmi Toyota yang menjual mobil bekas Toyota berkualitas dan bersertifikasi sehingga pelanggan dapat menikmati garansi resmi dari PT Toyota Astra Motor. Setiap mobil bekas Toyota sudah melalui proses pengecekan di 203 titik, sehingga dapat dipastikan aman dan transparan.",
        approach:
            "Sistem Toyota TRUST berbasiskan Web Application yang terintergasi dengan aplikasi inspeksi, aplikasi sales, dan aplikasi Enterprise Resource Planning (ERP). ",
        tech_stack: "HTML, CSS, JavaScript, react.Js, Node.Js, Express.Js, React. Native",
        result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
        picture: CSIPortfolio
    },
    {
        name: "CSI Chat",
        overview:
            "Toyota TRUST adalah layanan resmi Toyota yang menjual mobil bekas Toyota berkualitas dan bersertifikasi sehingga pelanggan dapat menikmati garansi resmi dari PT Toyota Astra Motor. Setiap mobil bekas Toyota sudah melalui proses pengecekan di 203 titik, sehingga dapat dipastikan aman dan transparan.",
        approach:
            "Sistem Toyota TRUST berbasiskan Web Application yang terintergasi dengan aplikasi inspeksi, aplikasi sales, dan aplikasi Enterprise Resource Planning (ERP). ",
        tech_stack: "HTML, CSS, JavaScript, react.Js, Node.Js, Express.Js, React. Native",
        result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
        picture: CSIPortfolio
    },
    {
        name: "CSI Chat",
        overview:
            "Toyota TRUST adalah layanan resmi Toyota yang menjual mobil bekas Toyota berkualitas dan bersertifikasi sehingga pelanggan dapat menikmati garansi resmi dari PT Toyota Astra Motor. Setiap mobil bekas Toyota sudah melalui proses pengecekan di 203 titik, sehingga dapat dipastikan aman dan transparan.",
        approach:
            "Sistem Toyota TRUST berbasiskan Web Application yang terintergasi dengan aplikasi inspeksi, aplikasi sales, dan aplikasi Enterprise Resource Planning (ERP). ",
        tech_stack: "HTML, CSS, JavaScript, react.Js, Node.Js, Express.Js, React. Native",
        result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
        picture: CSIPortfolio
    },
    {
        name: "CSI Chat",
        overview:
            "Toyota TRUST adalah layanan resmi Toyota yang menjual mobil bekas Toyota berkualitas dan bersertifikasi sehingga pelanggan dapat menikmati garansi resmi dari PT Toyota Astra Motor. Setiap mobil bekas Toyota sudah melalui proses pengecekan di 203 titik, sehingga dapat dipastikan aman dan transparan.",
        approach:
            "Sistem Toyota TRUST berbasiskan Web Application yang terintergasi dengan aplikasi inspeksi, aplikasi sales, dan aplikasi Enterprise Resource Planning (ERP). ",
        tech_stack: "HTML, CSS, JavaScript, react.Js, Node.Js, Express.Js, React. Native",
        result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
        picture: CSIPortfolio
    },
    {
        name: "CSI Chat",
        overview:
            "Toyota TRUST adalah layanan resmi Toyota yang menjual mobil bekas Toyota berkualitas dan bersertifikasi sehingga pelanggan dapat menikmati garansi resmi dari PT Toyota Astra Motor. Setiap mobil bekas Toyota sudah melalui proses pengecekan di 203 titik, sehingga dapat dipastikan aman dan transparan.",
        approach:
            "Sistem Toyota TRUST berbasiskan Web Application yang terintergasi dengan aplikasi inspeksi, aplikasi sales, dan aplikasi Enterprise Resource Planning (ERP). ",
        tech_stack: "HTML, CSS, JavaScript, react.Js, Node.Js, Express.Js, React. Native",
        result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
        picture: CSIPortfolio
    },
    {
        name: "CSI Chat",
        overview:
            "Toyota TRUST adalah layanan resmi Toyota yang menjual mobil bekas Toyota berkualitas dan bersertifikasi sehingga pelanggan dapat menikmati garansi resmi dari PT Toyota Astra Motor. Setiap mobil bekas Toyota sudah melalui proses pengecekan di 203 titik, sehingga dapat dipastikan aman dan transparan.",
        approach:
            "Sistem Toyota TRUST berbasiskan Web Application yang terintergasi dengan aplikasi inspeksi, aplikasi sales, dan aplikasi Enterprise Resource Planning (ERP). ",
        tech_stack: "HTML, CSS, JavaScript, react.Js, Node.Js, Express.Js, React. Native",
        result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
        picture: CSIPortfolio
    }
]

const whyCODRdata = [
    {
        title: "Tailored AI Solutions",
        desc: "We recognize that every business is unique. Our AI solutions are meticulously crafted to align with your specific needs, ensuring maximum impact and value.",
        icon: PuzzleIcon
    },
    {
        title: "Proven Expertise",
        desc: "Our team consists of industry-leading AI experts, data scientists, and engineers with a track record of successful AI implementations across various sectors, including finance, healthcare, retail, and manufacturing.",
        icon: MedalIcon
    },
    {
        title: "End-to-End Implementation",
        desc: "From strategy to deployment and beyond, we provide comprehensive support throughout your AI journey, ensuring a seamless transition to AI-driven processes.",
        icon: ReloadIcon
    },
    {
        title: "Scalable AI Architectures",
        desc: "We build AI systems that are not only robust and efficient but also scalable. Whether you're a startup or a large enterprise, our solutions grow with your business, adapting to increasing demands and complexity.",
        icon: ResizeIcon
    }
]

const ourSolutionsData = [
    {
        title: "E-commerce",
        desc: "We help you navigate the complexities of AI adoption. Our consulting services focus on defining a clear AI strategy, identifying opportunities, and creating a roadmap that aligns with your business goals, while ensuring compliance with industry standards.",
        image: E_commerce,
        banner: AiSolutionEcommerce,
        description:
            "In today's rapidly evolving e-commerce landscape, delivering exceptional and personalized customer service is vital for maintaining a competitive edge. At CODR, we are dedicated to transforming customer interactions and elevating the support experience through our cutting-edge AI solutions.<br/><br/>Our mission is clear: integrate innovative AI technologies into existing frameworks to achieve remarkable results. Our approach is designed to drastically reduce response times, boost customer satisfaction, and provide round-the-clock support, all while ensuring interactions remain engaging and human-like.<br/><br/><b><h2>AI-Powered Customer Service Chatbot</h2></b><br/>We have designed and implemented a state-of-the-art AI customer service chatbot. This solution harnesses advanced machine learning algorithms, natural language processing (NLP), and automated workflows to swiftly and accurately address customer inquiries. By leveraging these technologies, we empower businesses to enhance operational efficiency and foster stronger relationships with customers, driving loyalty and repeat business.",
        child: [
            {
                img: AiSolutionEcommerceChild1,
                content:
                    "The AI chatbot provides round-the-clock assistance, responding to thousands of queries simultaneously, reducing wait times dramatically."
            },
            {
                img: AiSolutionEcommerceChild2,
                content:
                    "Using NLP, the bot understands the context and intent behind customer questions, delivering accurate answers to a wide range of inquiries from product availability to order tracking."
            },
            {
                img: AiSolutionEcommerceChild3,
                content:
                    "For complex or sensitive cases, the bot intelligently escalates queries to human agents with complete context, improving resolution efficiency."
            }
        ],
        benefit: {
            title: "Benefits of AI in E-Commerce",
            child: [
                {
                    title: "Increased Conversion Rates",
                    description:
                        "By offering personalized product recommendations and dynamic pricing, AI helps e-commerce companies convert visitors into customers more effectively."
                },
                {
                    title: "Enhanced Customer Experience",
                    description:
                        "AI-powered chatbots, personalized recommendations, and visual search make the shopping experience more seamless and enjoyable for customers."
                },
                {
                    title: "Improved Operational Efficiency",
                    description:
                        "AI optimizes supply chains and inventory management, reducing costs and increasing efficiency in e-commerce operations."
                }
            ]
        },
        outro: "With our AI solutions, your business can achieve unparalleled success and customer satisfaction."
    },
    {
        title: "Health Care",
        desc: "We help you navigate the complexities of AI adoption. Our consulting services focus on defining a clear AI strategy, identifying opportunities, and creating a roadmap that aligns with your business goals, while ensuring compliance with industry standards.",
        image: Health_Care,
        banner: AiSolutionHealtcare,
        description:
            "Accurate billing and cost estimation are paramount in the healthcare industry, ensuring transparency for patients and enhancing operational efficiency for hospitals. At CODR, we have developed an AI-powered solution designed to automate the estimation of medical bills, delivering clarity and precision for all stakeholders involved.<br/><br/>By leveraging patient data, historical invoices, and hospital records, our innovative system provides real-time cost predictions and financial evaluations. This empowers both patients and healthcare providers to make informed financial decisions, fostering better financial planning.<br/><br/><b><h2>AI-Driven Bill Estimation System</h2></b><br/>To tackle these challenges, we created an advanced AI-driven bill estimation system that integrates seamlessly with existing hospital databases and billing systems. Utilizing sophisticated machine learning models and data analytics, our solution estimates costs based on various factors, including the patient's condition, past treatment history, and current hospital data.",
        child: [
            {
                img: AiSolutionHealtcareChild1,
                content:
                    "The AI considers individual patient conditions, treatments prescribed, and insurance coverage to generate tailored cost estimates in real-time."
            },
            {
                img: AiSolutionHealtcareChild2,
                content:
                    "By analyzing historical invoices and patient data, the AI provides insights into common billing patterns and potential cost-saving measures for the hospital."
            },
            {
                img: AiSolutionHealtcareChild3,
                content:
                    "The system uses historical data to predict future billing trends, helping hospitals optimize their pricing models and improve financial forecasting."
            }
        ],
        benefit: {
            title: "Benefits of AI in Healthcare",
            child: [
                {
                    title: "Faster Decision-Making",
                    description:
                        "AI-powered chatbots, personalized recommendations, and visual search make the shopping experience more seamless and enjoyable for customers."
                },
                {
                    title: "Improved Patient Experience",
                    description:
                        "AI tools provide 24/7 support through chatbots, virtual health assistants, and remote monitoring, improving patient satisfaction and engagement."
                },
                {
                    title: "Cost Reduction",
                    description:
                        "By automating routine tasks and reducing diagnostic errors, AI reduces the overall cost of healthcare services."
                },
                {
                    title: "Increased Accuracy",
                    description:
                        "AI helps eliminate human error in diagnostics and treatment, leading to improved patient outcomes."
                }
            ]
        },
        outro: "With our AI solutions, your business can achieve unparalleled success and customer satisfaction."
    },

    {
        title: "Automotive Auction",
        desc: "We help you navigate the complexities of AI adoption. Our consulting services focus on defining a clear AI strategy, identifying opportunities, and creating a roadmap that aligns with your business goals, while ensuring compliance with industry standards.",
        image: Automotive_Auction,
        banner: AiSolutionAuction,
        description:
            "The automotive auction industry depends on timely and accurate information for both sellers and buyers. To streamline customer interactions and provide seamless support, we have developed an AI-powered chatbot specifically designed for automotive auction platforms.<br/><br/>This intelligent system enables users to receive instant answers to their inquiries about car details, auction procedures, promotions, and more— all in real-time. By enhancing accessibility to information, we create a smoother and more efficient customer experience.<br/><br/><b><h2>AI-Driven Chatbot for Automotive Auctions</h2></b><br/>Our AI-driven chatbot is integrated seamlessly with the auction app, effectively managing customer service inquiries with speed and accuracy. This innovative solution not only reduces the workload on customer service teams but also enhances user satisfaction by providing quick and reliable responses.",
        child: [
            {
                img: AiSolutionAuctionChild1,
                content:
                    "The chatbot provides detailed information about vehicles, including specifications, history, and bidding status, helping users make informed decisions."
            },
            {
                img: AiSolutionAuctionChild2,
                content:
                    "The AI system operates continuously, allowing users to access support anytime, even outside regular business hours."
            },
            {
                img: AiSolutionAuctionChild3,
                content:
                    "For more complex queries, the chatbot escalates the conversation to human agents with full context, ensuring smooth transitions."
            }
        ],
        benefit: {
            title: "Benefits of AI in Automotive Auctions",
            child: [
                {
                    title: "Proactive Customer Engagement with AI-Powered Predictive Analytics",
                    description:
                        "Enable auction platforms to anticipate future vehicle demand and outcomes, allowing sellers to time their listings effectively. Buyers also receive notifications when vehicles matching their preferences are likely to be listed, enhancing engagement and customer retention."
                },
                {
                    title: "Boosting Buyer Confidence with Personalized Recommendations",
                    description:
                        "AI analyzes buyer preferences, past bidding history, and behavior to deliver tailored vehicle recommendations. This personalized assistance makes the search process faster and more relevant, increasing buyer confidence and satisfaction."
                },
                {
                    title: "Seamless Sales Transactions Through AI Automation",
                    description:
                        "AI automates key sales processes such as bidder verification, payment confirmations, and post-auction paperwork. This automation eliminates delays and ensures smooth transaction flows for both buyers and sellers."
                },
                {
                    title: "Enhanced Customer Support with AI Chatbots",
                    description:
                        "AI-powered chatbots and virtual assistants provide 24/7 customer support to buyers and sellers, answering questions about vehicles, auction processes, and providing bid status updates. This ensures real-time assistance without human intervention, improving overall satisfaction and reducing wait times."
                }
            ]
        },
        outro: "With our AI solutions, your business can achieve unparalleled success and customer satisfaction."
    }
]

const someProject = [
    {
        id: "WA-1",
        title: "Toyota Trust E-Commerce",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
            overview:
                "Toyota TRUST adalah layanan resmi Toyota yang menjual mobil bekas Toyota berkualitas dan bersertifikasi sehingga pelanggan dapat menikmati garansi resmi dari PT Toyota Astra Motor. Setiap mobil bekas Toyota sudah melalui proses pengecekan di 203 titik, sehingga dapat dipastikan aman dan transparan.",
            approach:
                "Sistem Toyota TRUST berbasiskan Web Application yang terintergasi dengan aplikasi inspeksi, aplikasi sales, dan aplikasi Enterprise Resource Planning (ERP).Teknologi yang digunakan adalah: HTML, CSS, JavaScript, react.Js, Node.Js, Express.Js, React. Native",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
            cardImage: "/tt/tt1.png"
        }
    },
    {
        id: "WA-4",
        title: "Hybid Lelang (hybid.id)",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
            overview:
                "HYBID merupakan produk dari PT. Balai Lelang Anugerah adalah balai lelang yang menyediakan jasa lelang terbuka dengan pengelolaan yang professional, transparan dan terpercaya. Proses lelang dapat dilakukan secara hybrid, dimana customer dapat melakukan Bid melalui website ataupun datang ke lokasi lelang. Platform lelang yang dibangun meliputi. Website lelang customer, website untuk konduktor lelang, dan admin panel untuk mengelola operasional internal perusahaan.",
            approach:
                "Platform lelang yang dibangun meliputi. Website lelang customer, website untuk konduktor lelang, dan admin panel untuk mengelola operasional internal perusahaan. Pada aplikasi lelang ini sistem menggunakan websocket untuk mengakomodir kebutuhan event lelang secara real-time. \nTech Stack: HTML, CSS, JavaScript, React.Js, PHP, Laravel, Websocket, Xendit integrated",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
            cardImage: "/hybid/hybid1.png"
        }
    },
    {
        id: "MA-2",
        title: "Rumah Siap Kerja",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
            overview:
                "Rumah Siap Kerja merupakan Platform pendidikan untuk pengembangan skills professional dan wirausaha baik secara soft skill maupun hardskills. yang menyediakan sertifikat resmi untuk setiap peserta yang mengikuti sesi pelatihan dari Rumah Siap Kerja. Aplikasi ini menyediakan daftar pelatihan dengan kategori bidang pelatihan, informasi detail materi, rincial silabus, dan fitur pembayaran online untuk pembelian materi",
            approach:
                "Aplikasi Rumah Siap Kerja dibangun menggunakan framework react native (cross-platform framework) agar aplikasi dapat diakses oleh pengguna Andorid dan IOS sekaligus. User dapat mengakses aplikasi Rumah Siap Kerja melalui: \nGoogle play: https://play.google.com/store/apps/details?id=com.rumahsiapkerja",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
            cardImage: "/rsk/rsk1.png"
        }
    },
    {
        id: "MA-1",
        title: "Digital Appraisal",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
            overview:
                "Digital Appraisal adalah aplikasi mobile untuk mengakomodir proses inspeksi kendaraan untuk memberikan penilaian terhadap kualitas kendaraan yang akan diperjualbelikan. Pada aplikasi ini, setiap kendaraan yang sudah selesai di inspeksi, akan diberikan skor sebagai acuan untuk pemberian rekomendasi harga kendaraan.",
            approach:
                "Sistem dibangun dengan framework React Native (cross-platform framework) agar dapat diakses oleh pengguna Android dan IOS. Sistem ini terintegrasi dengan sistem aplikasi sales dan Enterprise Resource Planning (ERP).\nTech Stack: HTML, CSS, JavaScript, React.Js, Node.Js, Express.Js, React. Native",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
            cardImage: "/digital/digital1.png"
        }
    }
]

export {
    clients,
    FAQList,
    projectCategory,
    someProject,
    ourValues,
    whyCODRdata,
    ourSolutionsData,
    dataTestimoni,
    dataPortfolio
}
