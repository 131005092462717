import React from 'react'
import OurPortfolio from '../Home/OurPortfolio'
import { dataPortfolio } from "../../pages/Homepage/homepageData"

const Portfolio = () => {
  return (
    <div className='pt-32'>
        <OurPortfolio data={dataPortfolio}/>
    </div>
  )
}

export default Portfolio