import React from "react"
import {
    CustomSolution1,
    CustomSolution2,
    CustomSolution3,
    CustomSolution4,
    CustomSolution5
} from "../../assets/images/solution"
import OurPortfolio from "../../components/Home/OurPortfolio"
import { dataPortfolio } from "../Homepage/homepageData"

const CustomSolution = () => {
    return (
        <div className="pt-28">
            <section className="h-full px-8 sm:px-10 xl:px-0 max-w-[1200px] m-auto w-full py-16 sm:py-20 flex flex-col gap-24 md:gap-40">
                <div className="flex flex-col md:flex-row items-center md:gap-12 ">
                    <div className="flex flex-col gap-8">
                        <p className="font-bold text-2xl sm:text-4xl">
                            Custom Solutions: Tailored to Your Unique Business Needs
                        </p>
                        <p className="text-base sm:text-xl">
                            Our Custom Solutions service is built to create products that solve your business challenges
                            with precision and efficiency.
                        </p>
                    </div>
                    <img src={CustomSolution1} alt={"CustomSolution1"} className="" />
                </div>

                <div className="flex flex-col md:flex-row-reverse items-center gap-12 ">
                    <div className="flex flex-col gap-4 sm:p-10 w-full max-w-[666px]">
                        <p className="font-bold text-2xl sm:text-3xl">Fully Customizable</p>
                        <p className="">
                            Every aspect of the product is built specifically for your business, ensuring it aligns with
                            your goals and workflows.
                        </p>
                    </div>
                    <div className="w-full max-w-[450px] max-h-[450px] p-4 bg-[#F7F7F7] rounded-md">
                        <img src={CustomSolution2} alt={"CustomSolution2"} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row items-center gap-12 ">
                    <div className="flex flex-col gap-4 sm:p-10 w-full max-w-[666px]">
                        <p className="font-bold text-2xl sm:text-3xl">Scalable Development</p>
                        <p className="">
                            Our solutions are designed to grow with your business, ensuring long-term efficiency and
                            adaptability.{" "}
                        </p>
                    </div>
                    <div className="w-full max-w-[450px] max-h-[450px] p-4 bg-[#F7F7F7] rounded-md">
                        <img src={CustomSolution3} alt={"CustomSolution3"} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row-reverse items-center gap-12 ">
                    <div className="flex flex-col gap-4 sm:p-10 w-full max-w-[666px]">
                        <p className="font-bold text-2xl sm:text-3xl">Integration Ready</p>
                        <p className="">
                            We build products that integrate seamlessly with your existing systems, ensuring a smooth
                            and hassle-free transition.
                        </p>
                    </div>
                    <div className="w-full max-w-[450px] max-h-[450px] p-4 bg-[#F7F7F7] rounded-md">
                        <img src={CustomSolution4} alt={"CustomSolution4"} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row items-center gap-12 ">
                    <div className="flex flex-col gap-4 sm:p-10 w-full max-w-[666px]">
                        <p className="font-bold text-2xl sm:text-3xl">User-Centric Design</p>
                        <p className="">
                            We focus on delivering products that are intuitive and easy to use, enhancing user
                            experience and satisfaction.
                        </p>
                    </div>
                    <div className="w-full max-w-[450px] max-h-[450px] p-4 bg-[#F7F7F7] rounded-md">
                        <img src={CustomSolution5} alt={"CustomSolution5"} />
                    </div>
                </div>

                    <OurPortfolio data={dataPortfolio.map((data, i) => i < 3 && data).filter((data) => data)} />

                <div className="flex flex-col items-center sm:p-10 w-full max-w-[666px]-center gap-10">
                    <p className="font-bold text-2xl sm:text-3xl">Solutions We Provide</p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-between gap-12 sm:gap-6 bg-[#F7F7F7] p-8 md:p-12 ">
                        <div className="flex flex-col gap-2 ">
                            <p className="text-2xl font-bold">Software Development</p>
                            <p>
                                Custom software built to solve specific business challenges, from internal tools to
                                customer-facing platforms.
                            </p>
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <p className="text-2xl font-bold">Mobile Applications</p>
                            <p>
                                We develop mobile apps that enhance customer engagement, streamline operations, or offer
                                new services.
                            </p>
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <p className="text-2xl font-bold">Web App</p>
                            <p>
                                Whether it’s an e-commerce platform, a booking system, or a content management tool, we
                                deliver custom-built web solutions.
                            </p>
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <p className="text-2xl font-bold">System Integration</p>
                            <p>
                                We can integrate new tools and technologies into your existing infrastructure, creating
                                a seamless ecosystem for your business.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="py-24">
                    <FAQ />
                </div> */}
            </section>
        </div>
    )
}

export default CustomSolution
