import React from "react"
import { secondary } from "../../assets/strings/variables"
import { ourValues } from "../../pages/Homepage/homepageData"

function Values() {
  return (
    <section
      id="our-values"
      className="h-full grid grid-cols-1 px-6 sm:px-10 xl:px-0 max-w-[1200px] w-full py-14 sm:py-20 m-auto"
    >
      <div className="text-[32px] text-center font-semibold">Our Values</div>
      {/* container */}
      <div className="py-6 sm:py-10 grid">
        {/* grid content */}
        <div className="grid grid-cols-1 md:grid-cols-2 xsm:gap-3 md:gap-5">
          {ourValues.map(({ title, desc, icon: Icon }, idx) => (
            <div
              className="text-center xsm:p-2 md:p-4 min-h-[130px] flex gap-4"
              key={`value-${idx}`}
            >
              <div className="rounded-full h-[40px] w-[40px] mb-6 flex items-center justify-center">
                  <Icon />
              </div>
              <div className="flex flex-col text-left pt-1">
                <div className="font-semibold mb-2 xsm:text-base md:text-2xl">
                  {title}
                </div>
                <div className={`text-[${secondary}] xsm:text-sm md:text-base`}>
                  {desc}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Values
