import AiSolution1 from "./ai-solution-1.png"
import AiSolution2 from "./ai-solution-2.png"
import AiSolution3 from "./ai-solution-3.png"
import AiSolution4 from "./ai-solution-4.png"
import AiSolution5 from "./ai-solution-5.png"

import CustomSolution1 from "./custom-solution-1.png"
import CustomSolution2 from "./custom-solution-2.png"
import CustomSolution3 from "./custom-solution-3.png"
import CustomSolution4 from "./custom-solution-4.png"
import CustomSolution5 from "./custom-solution-5.png"

import AiSolutionEcommerce from "./ai-solution-ecommerce.png"
import AiSolutionEcommerceChild1 from "./ai-solution-ecommerce-child-1.png"
import AiSolutionEcommerceChild2 from "./ai-solution-ecommerce-child-2.png"
import AiSolutionEcommerceChild3 from "./ai-solution-ecommerce-child-3.png"

import AiSolutionHealtcare from "./ai-solution-healtcare.png"
import AiSolutionHealtcareChild1 from "./ai-solution-healtcare-child-1.png"
import AiSolutionHealtcareChild2 from "./ai-solution-healtcare-child-2.png"
import AiSolutionHealtcareChild3 from "./ai-solution-healtcare-child-3.png"

import AiSolutionAuction from "./ai-solution-auction.png"
import AiSolutionAuctionChild1 from "./ai-solution-auction-child-1.png"
import AiSolutionAuctionChild2 from "./ai-solution-auction-child-2.png"
import AiSolutionAuctionChild3 from "./ai-solution-auction-child-3.png"

export {
    AiSolution1,
    AiSolution2,
    AiSolution3,
    AiSolution4,
    AiSolution5,
    CustomSolution1,
    CustomSolution2,
    CustomSolution3,
    CustomSolution4,
    CustomSolution5,
    AiSolutionEcommerce,
    AiSolutionEcommerceChild1,
    AiSolutionEcommerceChild2,
    AiSolutionEcommerceChild3,
    AiSolutionHealtcare,
    AiSolutionHealtcareChild1,
    AiSolutionHealtcareChild2,
    AiSolutionHealtcareChild3,
    AiSolutionAuction,
    AiSolutionAuctionChild1,
    AiSolutionAuctionChild2,
    AiSolutionAuctionChild3
}
