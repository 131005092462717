import React, { useEffect, useState } from "react"
import { dataPortfolio } from "../../pages/Homepage/homepageData"
import { useParams } from "react-router"

const DetailPortfolio = () => {
    const [Project, setProject] = useState(null)
    let { id } = useParams()

    useEffect(() => {
        let findPorto = dataPortfolio.find((data) => data.name === id.replace("_"," "))
        console.log("findPorto", findPorto)
        if (findPorto) setProject(findPorto)
    }, [id])

    return (
        <div className="pt-20">
            <section className="h-full px-6 sm:px-10 xl:px-0 max-w-[1200px] m-auto grid grid-cols-1 w-full py-14 sm:py-20">
                <div className="flex-col pt-16 xsm:gap-3 md:gap-5 gap-y-12">
                    {Project && (
                        <div>
                            <div className="xsm:p-3 md:p-6 min-h-[130px] flex justify-center bg-[#F7F7F7] rounded-lg cursor-pointer max-h-[400px] w-full relative">
                                {Project.picture && (
                                    <img src={Project.picture} alt={Project.name} className="h-full w-fit" />
                                )}
                                <p className="font-semibold text-4xl md:text-6xl absolute left-[22%] md:left-8 bottom-12">{Project.name}</p>
                            </div>

                            <div className="flex flex-col gap-4 md:gap-0 md:flex-row text-left  my-16">
                                <p className="text-left text-xl font-bold w-full md:w-[15%]">Overview</p>
                                <div className="flex-col w-full md:w-[85%]">
                                    <p className="text-left text-xl">{Project.overview}</p>

                                    <div className="xsm:p-3 md:p-6 min-h-[300px] flex justify-center bg-[#F7F7F7] rounded-lg cursor-pointer max-h-[400px] w-full relative mt-12">
                                        {/* {Project.picture && (
                                    <img src={Project.picture} alt={Project.name} className="h-full w-fit" />
                                )} */}
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-4 md:gap-0 md:flex-row text-left  my-16">
                                <p className="text-left text-xl font-bold w-full md:w-[15%]">Approach</p>
                                <div className="flex flex-col gap-5 w-full md:w-[85%]">
                                    <p className="text-left text-xl">{Project.approach}</p>

                                    <div className="w-full relative flex flex-col gap-3 ">
                                        <p className="text-left text-xl font-bold">Tech Stach</p>
                                        <p className="text-left text-xl">{Project.tech_stack}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-4 md:gap-0 md:flex-row text-left  my-16">
                                <p className="text-left text-xl font-bold w-full md:w-[15%]">Result</p>
                                <div className="flex flex-col gap-5 w-full md:w-[85%]">
                                    <p className="text-left text-xl">{Project.result}</p>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}

export default DetailPortfolio
