import React from "react"
import "./App.css"
import { Route, Routes } from "react-router-dom"
import Homepage from "./pages/Homepage/Homepage"
// import Blog from "./pages/Blog/Blog";
// import Project from "./pages/Project/Project";
// import ProjectDetail from "./pages/ProjectDetail/ProjectDetail";
import ScrollToTop from "./components/ScrollToTop"
import Layout from "./components/Layout/Layout"
import Portfolio from "./components/Portfolio"
import { AiSolution, CustomSolution } from "./pages/Solution"
import AiSolutionDetail from "./pages/Solution/AiSolutionDetail"
import DetailPortfolio from "./components/Portfolio/detail"

function App() {
    return (
        <>
            <ScrollToTop />

            <Routes>
                <Route
                    path="/"
                    element={
                        <Layout isHome={true}>
                            <Homepage />
                        </Layout>
                    }
                />
                <Route
                    path="/id"
                    element={
                        <Layout isHome={true}>
                            <Homepage />
                        </Layout>
                    }
                />
                <Route
                    path="/custom-solution"
                    element={
                        <Layout>
                            <CustomSolution />
                        </Layout>
                    }
                />
                <Route
                    path="/ai-solution"
                    element={
                        <Layout>
                            <AiSolution />
                        </Layout>
                    }
                />
                <Route
                    path="/ai-solution/:id"
                    element={
                        <Layout>
                            <AiSolutionDetail />
                        </Layout>
                    }
                />
                <Route
                    path="/our-portfolio"
                    element={
                        <Layout>
                            <Portfolio />
                        </Layout>
                    }
                />
                <Route
                    path="/our-portfolio/:id"
                    element={
                        <Layout>
                            <DetailPortfolio />
                        </Layout>
                    }
                />
                <Route
                    path="/projects"
                    element={
                        <Layout>
                            <Portfolio />
                        </Layout>
                    }
                />
                <Route
                    path="/projects/:id"
                    element={
                        <Layout>
                            <Portfolio />
                        </Layout>
                    }
                />
            </Routes>
        </>
    )
}

export default App
