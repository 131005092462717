import { footerLogo } from "../../assets/images/Nav"
import { base } from "../../assets/strings/variables"
import React from "react"
const Footer = () => {
    return (
        <footer>
            <div className={`bg-[${base}] flex flex-col items-center sm:m-10 md:rounded-lg`}>
                <div className="text-[#C0C0C0] flex flex-wrap max-w-[1200px] w-full text-xl p-6 py-10 lg:p-auto lg:py-20">
                    <div className="w-full flex flex-wrap justify-between gap-10 lg:gap-0 xl:gap-20 pb-6 lg:pb-10 border-b-[1px]">
                        <div className="flex flex-col items-start justify-between max-w-[500px] gap-4 ">
                            <img src={footerLogo} loading="lazy" alt="codr footer" className="h-10" />
                            <div className="text-base">
                                Ebenezer Building, Jl. Setia Budi Selatan No.1, RT.5/RW.5, Kuningan, Karet, Kecamatan
                                Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920
                            </div>
                            <div className="text-base ">Contact Us: +62 878-8193-0558</div>
                        </div>
                        <div className="text-2xl xl:text-[36px] xl:leading-[56px] text-[#FFFFFF] font-semibold max-w-[500px]">
                            Unleash Your Potential with Custom AI Solutions
                        </div>
                    </div>
                    <div className={`pt-6 lg:pt-10 text-base flex justify-between`}>
                        Copyright © 2022 PT Kode Inovasi Teknologi. All Rights Reserved
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
