import React from "react"
import { NavLink } from "react-router-dom"

function OurPortfolio({ data }) {
    console.log("data", data)

    return (
        <section
            id="our-portfolio"
            className="h-full px-6 sm:px-10 xl:px-0 max-w-[1200px] m-auto grid grid-cols-1 w-full py-14 sm:py-20"
        >
            <div className="text-[32px] text-center font-semibold">Our Portfolio</div>
            <div className="grid grid-cols-1 md:grid-cols-3 pt-16 gap-5 gap-y-12">
                {data.map(({ name, result, picture }, idx) => (
                    <NavLink className="flex-col gap-4 max-w-[430px] m-auto" key={idx} to={"/our-portfolio/" + name.replace(" ", "_")}>
                        <div
                            className="text-center xsm:p-3 md:p-6 min-h-[130px] flex gap-4 flex-col bg-[#F7F7F7] rounded-lg cursor-pointer"
                            key={`value-${idx}`}
                        >
                            {picture && <img src={picture} alt={name} />}
                        </div>

                        <div className="flex flex-col text-left gap-2 mt-2">
                            <p className="font-semibold text-base">{name}</p>
                            <p className="text-left text-base">
                                {result.split("").filter((str, i) => i < 100 && str)}...
                            </p>
                            <p className="text-left text-base text-gray-400 cursor-pointer">Read more</p>
                        </div>
                    </NavLink>
                ))}
            </div>
        </section>
    )
}

export default OurPortfolio
