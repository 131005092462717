import React from "react"
import { AiSolution1, AiSolution2, AiSolution3, AiSolution4, AiSolution5 } from "../../assets/images/solution"
import FAQ from "../../components/Home/FAQ"

const AiSolution = () => {
    return (
        <div className="pt-28">
            <section className="h-full px-8 sm:px-10 xl:px-0 max-w-[1200px] m-auto w-full py-16 sm:py-20 flex flex-col gap-24 md:gap-40">
                <div className="flex flex-col md:flex-row items-center gap-12 ">
                    <div className="flex flex-col gap-8">
                        <p className="font-bold text-2xl sm:text-4xl">
                            AI Solutions: Revolutionizing Problem Solving with Artificial Intelligence
                        </p>
                        <p className="text-base sm:text-xl">
                            We specialize in creating intelligent systems that seamlessly integrate AI technology to
                            enhance efficiency, decision-making, and customer experience.
                        </p>
                    </div>
                    <img src={AiSolution1} alt={"AiSolution1"} />
                </div>

                <div className="flex flex-col md:flex-row-reverse items-center gap-12 ">
                    <div className="flex flex-col gap-4 sm:p-10 w-full max-w-[666px]">
                        <p className="font-bold text-2xl sm:text-3xl">Data Analytics & Insights</p>
                        <p className="">
                            Unlock the full potential of your data with our advanced analytics services. We provide deep
                            insights that inform strategic decisions, helping you stay ahead of the competition,
                            securely and compliantly.
                        </p>
                    </div>

                    <div className="w-full max-w-[450px] max-h-[450px] p-4 bg-[#F7F7F7] rounded-md">
                        <img src={AiSolution2} alt={"AiSolution2"} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row items-center gap-12 ">
                    <div className="flex flex-col gap-4 sm:p-10 w-full max-w-[666px]">
                        <p className="font-bold text-2xl sm:text-3xl">AI Strategy & Consulting</p>
                        <p className="">
                            We help you navigate the complexities of AI adoption. Our consulting services focus on
                            defining a clear AI strategy, identifying opportunities, and creating a roadmap that aligns
                            with your business goals, while ensuring compliance with industry standards.
                        </p>
                    </div>

                    <div className="w-full max-w-[450px] max-h-[450px] p-4 bg-[#F7F7F7] rounded-md">
                        <img src={AiSolution3} alt={"AiSolution3"} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row-reverse items-center gap-12 ">
                    <div className="flex flex-col gap-4 sm:p-10 w-full max-w-[666px]">
                        <p className="font-bold text-2xl sm:text-3xl">AI-Driven Customer Experience Enhancement</p>
                        <p className="">
                            Enhance your customer experience with AI solutions that provide personalized, responsive,
                            and proactive service. Our AI-driven customer experience solutions help you build stronger
                            relationships and increase customer loyalty.
                        </p>
                    </div>

                    <div className="w-full max-w-[450px] max-h-[450px] p-4 bg-[#F7F7F7] rounded-md">
                        <img src={AiSolution4} alt={"AiSolution4"} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row items-center gap-12 ">
                    <div className="flex flex-col gap-4 sm:p-10 w-full max-w-[666px]">
                        <p className="font-bold text-2xl sm:text-3xl">AI-Driven Customer Experience Enhancement</p>
                        <p className="">
                            Enhance your customer experience with AI solutions that provide personalized, responsive,
                            and proactive service. Our AI-driven customer experience solutions help you build stronger
                            relationships and increase customer loyalty.
                        </p>
                    </div>

                    <div className="w-full max-w-[450px] max-h-[450px] p-4 bg-[#F7F7F7] rounded-md">
                        <img src={AiSolution5} alt={"AiSolution5"} />
                    </div>
                </div>

                <div className="flex flex-col items-center sm:p-10 w-full max-w-[666px]-center gap-10">
                    <p className="font-bold text-2xl sm:text-3xl">How We Work</p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-between gap-12 sm:gap-6 bg-[#F7F7F7] p-8 md:p-12 ">
                        <div className="flex flex-col gap-2 ">
                            <p className="text-2xl font-bold">Consultation</p>
                            <p>
                                We begin by understanding your business challenges and the potential areas where AI can
                                make an impact.
                            </p>
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <p className="text-2xl font-bold">Custom Solution Design</p>
                            <p>
                                Our team of experts designs a tailor-made AI solution that integrates seamlessly with
                                your current systems.
                            </p>
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <p className="text-2xl font-bold">Deployment & Integration</p>
                            <p>We ensure a smooth implementation and provide support during the transition period.</p>
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <p className="text-2xl font-bold">Ongoing Support</p>
                            <p>
                                Post-launch, we offer maintenance, updates, and optimization to ensure your AI solution
                                continues to deliver value.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="py-24">
                    <FAQ />
                </div> */}
            </section>
        </div>
    )
}

export default AiSolution
