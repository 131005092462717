import React from "react"
import { useEffect, useState } from "react"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import LiveChat from "../live-chat"

const Layout = ({ children, isHome = false }) => {
    const [active, setActive] = useState(true)

    const checkTop = () => {
        const yPos = window.pageYOffset
        const widthWindow = window.innerWidth
        let threshold = 90
        if (widthWindow < 1024) {
            threshold = 45
        } else {
            threshold = 90
        }
        if (yPos < threshold) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    // const scrollToTop = () => {
    //     document.body.scrollTo({
    //         top: 0,
    //         behavior: 'smooth',
    //     })
    //     document.documentElement.scrollTo({
    //         top: 0,
    //         behavior: 'smooth',
    //     });
    // }

    useEffect(() => {
        window.onscroll = () => {
            checkTop()
        }
    }, [])

    return (
        <div className="relative">
            <Header active={active} isHome={isHome} />
            <div className="min-h-screen relative z-0" id="content">
                <div className="z-0 hidden md:block bg-gradient-to-bl from-[#6938EF1A] from-10% via-[#5BD0660A] via-30% to-[#FFFFFF00] to-90% w-[50vw] h-[80vh] absolute top-0 right-0" />
                <div className="z-10 relative">{children}</div>
            </div>
            <Footer />
            {/* <LiveChat /> */}
        </div>
    )
}

export default Layout
