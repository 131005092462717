import React from "react"
import { secondary } from "../../assets/strings/variables"
import { dataTestimoni } from "../../pages/Homepage/homepageData"

function Testimoni() {
    return (
        <section
            id="our-Testimoni"
            className="h-full grid grid-cols-1 px-6 sm:px-10 xl:px-0 max-w-[1200px] w-full py-14 sm:py-20 m-auto"
        >
            <div className="text-[32px] text-center font-semibold">Our Testimoni</div>
            {/* container */}
            <div className="py-6 sm:py-10 grid">
                {/* grid content */}
                <div className="flex xsm:gap-3 md:gap-5">
                    {dataTestimoni.map(({ name, from, testimoni, picture }, idx) => (
                        <div
                            className="text-center xsm:p-3 md:p-6 min-h-[130px] flex gap-4 flex-col bg-[#F7F7F7] rounded-lg"
                            key={`value-${idx}`}
                        >
                            <p className="text-left text-base">{testimoni}</p>
                            <div className="flex gap-2">
                                <div className="rounded-full bg-[#D9D9D9] w-16 h-16">
                                    {picture && <img src={picture} alt={name} />}
                                </div>
                                <div className="flex flex-col text-left pt-1">
                                    <div className="font-semibold mb-2 text-base">{name}</div>
                                    <div className={`text-[${secondary}] text-sm`}>{from}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Testimoni
