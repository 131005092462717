// import Layout from "../../components/Layout/Layout";
import React from "react"
import Hero from "../../components/Home/Hero"
import Client from "../../components/Home/Client"
import About from "../../components/Home/About"
import Services from "../../components/Home/Services"
import Values from "../../components/Home/Values"
import Testimoni from "../../components/Home/Testimoni"
import Projects from "../../components/Home/Projects"
import FAQ from "../../components/Home/FAQ"
import ProjectTotal from "../../components/Home/ProjectTotal"
import Consult from "../../components/Home/Consult"
import WhyCODR from "../../components/Home/WhyCODR"
import OurSolution from "../../components/Home/OurSolution"
import OurPortfolio from "../../components/Home/OurPortfolio"

import { dataPortfolio } from "../../pages/Homepage/homepageData"

const Homepage = () => {
    return (
        <div>
            <Hero />
            <Client />
            {/* <About /> */}
            {/* <Services /> */}
            <WhyCODR />
            <OurSolution />
            <Values />
            {/* <Testimoni /> */}
            {/* <OurPortfolio data={dataPortfolio.map((data, i) => i < 3 && data).filter((data) => data)} /> */}
            {/* <ProjectTotal /> */}
            {/* <Projects /> */}
            {/* <FAQ /> */}
            <Consult />
        </div>
    )
}

export default Homepage
